import { IAuction, IGeneral, IItem, ILive } from '../../models';
import { SearchItemResponse } from '../../services/api/response.types';

type FlatResponse =
  | IItem.Flat.ItemFlat
  | IItem.Flat.PrebidFlat
  | IItem.Flat.FavoriteFlat
  | IItem.Flat.PurchaseFlat
  | IItem.Flat.PurchaseV2Flat
  | IItem.Flat.PaymentDetailsFlat
  | IItem.Flat.OfferFlat
  | IItem.Flat.RecommendationFlat
  | IItem.Flat.EbidItemFlat
  | IItem.Flat.BidActivityFlat;

export const composeItemFromFlatResponse = (
  resp: FlatResponse,
  responseType?: IItem.Flat.ResponseType,
): IItem.Item => {
  let item: IItem.Item = {
    conditionType: resp.conditionType,
    depositAmt: resp.depositAmt,
    eventCategory: resp.eventCategory,
    eventType: resp.eventType,
    id: resp.id,
    image: resp.image,
    images: resp.images,
    itemType: resp.type || resp.itemType,
    itemUpdated: resp.itemUpdated,
    minStartingBidAmt: resp.minStartingBidAmt,
    name: resp.name,
    notes: resp.notes,
    saleItemCreated: resp.saleItemCreated,
    saleItemUpdated: resp.saleItemUpdated,
    sourceType: resp.sourceType,
    status: resp.status,
    stockNum: resp.stockNum,

    yard: {
      city: resp.city,
      location: resp.yardLoc,
    },
    consignor: {
      name: resp.consignorName,
      type: resp.consignType,
    },

    viewsCount: resp.viewsCount,
  };

  // item.saleEvent?
  if (resp.eventType && resp.saleEventId && resp.saleEventName) {
    item.saleEvent = {
      id: resp.saleEventId,
      name: resp.saleEventName,
      startAt: resp.saleEventStartAt,
      endAt: resp.saleEventEndAt,
      lotNum: resp.lotNum,
    };
  }

  // item.vehicle?
  if (resp.make && resp.model) {
    item.vehicle = {
      color: resp.color,
      make: resp.make,
      year: resp.year,
      model: resp.model,
      regNo: resp.regNo,
      trim: resp.trim, // trim = variant
      vin: resp.vin, // vin = chassisNumber
      engineRef: resp.engineRef, // engineRef = engineNumber
      odoUnits: resp.odoUnits,
      odometer: resp.odometer, // odometer = mileage
      keys: resp.keys,
      series: resp.series,
      crIncidentType: resp.crIncidentType,
    };
  }

  switch (responseType) {
    case IItem.Flat.RESPONSE_TYPE.ITEM: {
      const _resp = resp as IItem.Flat.ItemFlat;
      item.auctionRemarks = _resp.auctionRemarks;
      item.inspectionRemarks = _resp.inspectionRemarks;
      item.viewsCount = _resp.viewsCount;
      break;
    }
    case IItem.Flat.RESPONSE_TYPE.PURCHASE: {
      const _resp = resp as IItem.Flat.PurchaseFlat;
      item.id = _resp.itemId;
      item.purchase = {
        dueAmt: _resp.dueAmt,
        moc: _resp.moc,
        payBy: _resp.payBy,
        showDueAmt: _resp.showDueAmt,
        soldAmt: _resp.soldAmt,
        soldAt: _resp.soldAt,
        towBy: _resp.towBy,
        needPayment: !!(_resp.dueAmt && _resp.dueAmt > 0),
      };
      break;
    }
    case IItem.Flat.RESPONSE_TYPE.PURCHASEV2: {
      const _resp = resp as IItem.Flat.PurchaseV2Flat;
      item.purchaseV2 = {
        soldAmt: _resp.soldAmt,
        soldAt: _resp.soldAt,
        isItemBalancePayableToConsignor: _resp.isItemBalancePayableToConsignor,
        payBy: _resp.payBy,
        finalizedAt: _resp.finalizedAt,
        ...setComputedPaymentInfo(
          _resp.status,
          _resp.isItemBalancePayableToConsignor,
          _resp.soldAmt,
          _resp.invoices,
        ),
      };

      break;
    }
    case IItem.Flat.RESPONSE_TYPE.PAYMENT_DETAILS: {
      const _resp = resp as IItem.Flat.PaymentDetailsFlat;
      item.purchaseV2 = {
        soldAmt: _resp.soldAmt,
        soldAt: _resp.soldAt,
        isItemBalancePayableToConsignor: _resp.isItemBalancePayableToConsignor,
        paymentTerms: IGeneral.PaymentTerms[_resp.paymentTerms],
        payBy: _resp.payBy,
        finalizedAt: _resp.finalizedAt,
        paymentSubmissions: _resp.paymentHistory,
        ...setComputedPaymentInfo(
          _resp.status,
          _resp.isItemBalancePayableToConsignor,
          _resp.soldAmt,
          _resp.invoices,
        ),
      };
      break;
    }
    case IItem.Flat.RESPONSE_TYPE.PREBID: {
      const _resp = resp as IItem.Flat.PrebidFlat;
      item.id = _resp.itemId;
      item.name = _resp.itemName;
      item.eventType = _resp.saleEventType;

      item.prebid = {
        id: _resp.id,
        itemId: _resp.itemId,
        amount: _resp.amount,
      };
      item.isLive = !!(_resp.saleEventType === IAuction.AUCTION_TYPES.LIVE);
      if (_resp.saleEventId && _resp.saleEventName && _resp.lotNum) {
        item.saleEvent = {
          id: _resp.saleEventId,
          name: _resp.saleEventName,
          startAt: _resp.saleStartAt,
          endAt: _resp.saleEndAt,
          lotNum: _resp.lotNum,
        };
      }
      break;
    }
    case IItem.Flat.RESPONSE_TYPE.FAVORITE: {
      const _resp = resp as IItem.Flat.FavoriteFlat;
      item.id = _resp.itemId;
      item.name = _resp.itemName;
      item.favorite = {
        favorite: _resp.favorite === 1,
        itemId: _resp.itemId,
      };
      item.isLive = !!(_resp.saleEventType === IAuction.AUCTION_TYPES.LIVE);
      item.isEbid = !!(_resp.saleEventType === IAuction.AUCTION_TYPES.STATIC);
      item.eventType = _resp.saleEventType;
      if (_resp.saleEventId && _resp.saleEventName && _resp.lotNum) {
        item.saleEvent = {
          id: _resp.saleEventId,
          name: _resp.saleEventName,
          lotNum: _resp.lotNum,
          startAt: _resp.saleStartAt,
          endAt: _resp.saleEndAt,
          type: _resp.saleEventType,
        };
      }
      break;
    }
    case IItem.Flat.RESPONSE_TYPE.OFFER: {
      const _resp = resp as IItem.Flat.OfferFlat;
      item.id = _resp.itemId;
      item.offer = {
        badgeNo: _resp.badgeNo,
        offerAmount: _resp.offerAmount,
        offerAt: _resp.offerAt,
        saleEventId: _resp.saleEventId,
        saleStatus: _resp.saleStatus,
      };
      if (item.bidding) {
        item.bidding.isOffer = true;
        item.bidding.isOfferWinner = true;
      } else {
        item.bidding = { isOffer: true, isOfferWinner: true };
      }

      break;
    }
    case IItem.Flat.RESPONSE_TYPE.RECOMMENDATION: {
      const _resp = resp as IItem.Flat.RecommendationFlat;
      if (_resp.saleEventId && _resp.saleEventName && _resp.lotNum) {
        item.saleEvent = {
          id: _resp.saleEventId,
          name: _resp.saleEventName,
          lotNum: _resp.lotNum,
        };
      }
      break;
    }
    case IItem.Flat.RESPONSE_TYPE.EBID: {
      const _resp = resp as IItem.Flat.EbidItemFlat;
      item.id = +_resp.clientItemId;
      item.eventType = IAuction.AUCTION_TYPES.STATIC;
      item.saleEvent = {
        id: +_resp.eventId,
        name: _resp.eventName,
        startAt: _resp.startAt,
        endAt: _resp.endAt,
      };
      break;
    }
    case IItem.Flat.RESPONSE_TYPE.BID_ACTIVITY: {
      const _resp = resp as IItem.Flat.BidActivityFlat;
      item.id = +_resp.clientItemId;
      item.saleEvent = {
        id: +_resp.eventId,
        name: _resp.eventName,
        lotNum: _resp.lotNum,
        startAt: _resp.startAt,
        endAt: _resp.endAt,
      };
      item.vehicle = {
        odometer: _resp.odometer,
        odoUnits: _resp.odoUnits,
      };
      break;
    }
  }

  return setComputed(item);
};

export const composeItemFromPrebidResponse = (response: IItem.Flat.ItemFlat): IItem.Item => {
  let item: Item = {
    yard: {
      city: response.city,
      location: response.yardLoc,
    },
    consignor: {
      name: response.consignorName,
      type: response.consignType,
    },
    id: response.id,
    eventType: response.eventType,
    itemType: response.itemType,
    depositAmt: response.depositAmt,
    image: response.image,
    images: response.images,
    itemUpdated: response.itemUpdated,
    name: response.name,
    saleItemUpdated: response.saleItemUpdated,
    saleItemCreated: response.saleItemCreated,
    sourceType: response.sourceType,
    status: response.status,
    stockNum: response.stockNum,
    odoUnits: response.odoUnits,
    odometer: response.odometer,
    conditionType: response.conditionType,
  };

  if (response.saleEventId && response.saleEventName) {
    item.saleEvent = {
      id: response.saleEventId,
      name: response.saleEventName,
      startAt: response.saleEventStartAt,
      endAt: response.saleEventEndAt,
      lotNum: response.lotNum,
    };
  }
  return item;
};

export const composeItemFromSearchItemResponse = (response: SearchItemResponse): IItem.Item => {
  let images: string[] = [];
  if (response.objectIds && response.objectIds.raw) {
    let _images = response.objectIds.raw.split(',');
    images = _images.map((img) => {
      return `${response.imagePrefix.raw}${img}`;
    });
  }

  let item: IItem.Item = {
    id: Number(response.id.raw),
    eventType: response.eventType.raw,
    itemType: response.itemType.raw,
    eventCategory: response.eventCategory.raw,
    depositAmt: response.depositAmt.raw,
    image: images[0],
    images: images,
    itemUpdated: response.updatedAt.raw,
    name: response.name.raw,
    saleItemUpdated: response.updatedAt.raw,
    saleItemCreated: response.createdAt.raw,
    sourceType: response.sourceType.raw,
    stockNum: response.stockNum.raw,
    yard: {
      city: '',
      location: null,
    },
    consignor: {
      name: '',
    },
    auctionRemarks: response.auctionRemarks.raw,
    inspectionRemarks: response.inspectionRemarks.raw,
    conditionType: response.conditionType.raw,
  };

  const { saleEventId, eventStart, eventEnd, lotNum } = response;
  if (saleEventId && !!eventStart?.raw && !!eventEnd?.raw) {
    item.saleEvent = {
      id: saleEventId.raw,
      name: '',
      startAt: eventStart.raw,
      endAt: eventEnd.raw,
      lotNum: lotNum?.raw,
    };
  }

  const { year, make, model, vin, color, odoUnits, odometer } = response;
  if (odoUnits.raw && odometer.raw) {
    item.vehicle = {
      year: year.raw,
      make: make.raw,
      model: model.raw,
      vin: vin.raw,
      color: color.raw,
      odoUnits: odoUnits.raw,
      odometer: odometer.raw,
    };
  }

  return setComputed(item);
};

export const composeItemFromLiveSocketResponse = (resp: ILive.VcastAuctionItem): IItem.Item => {
  const item: IItem.Item = {
    yard: {
      location: resp.YardLoc,
    },
    consignor: {
      name: resp.SalvageType,
    },
    vehicle: {
      vin: resp.VIN,
      crIncidentType: resp.CRIncidentType,
      engineRef: resp.Engine,
      regNo: resp.LicPlate,
      color: resp.Color,
      keys: resp.Keys,
      make: resp.Make,
      model: resp.Model,
      year: resp.VehicleYear,
    },
    saleEvent: {
      lotNum: resp.ItemNum,
    },
    id: Number(resp.ExternalID),
    eventType: 'LIVE',
    itemType: 'HALFCUT',
    depositAmt: resp.Deposit,
    image: resp.Images && resp.Images[0],
    images: resp.Images,
    itemUpdated: '',
    name: resp.Description,
    saleItemUpdated: null,
    saleItemCreated: null,
    sourceType: resp.ConsignType,
    stockNum: resp.StockNum,
    isLive: true,
  };

  return item;
};

const setComputed = (item: IItem.Item) => {
  const now = new Date().getTime();
  const startTime = item.bidding?.startTime || item.saleEvent?.startAt;
  const endTime = item.bidding?.endTime || item.saleEvent?.endAt;
  const isHold = !!(
    !item.saleEvent ||
    (item.saleEvent &&
      startTime &&
      endTime &&
      new Date(startTime).valueOf() < now &&
      new Date(endTime).valueOf() <= now)
  );

  return {
    ...item,
    isEbid: !!(item.eventType === IAuction.AUCTION_TYPES.STATIC),
    isLive: !!(item.eventType === IAuction.AUCTION_TYPES.LIVE),
    isHold,
  };
};

const setComputedPaymentInfo = (
  itemStatus: IItem.Status,
  isItemBalancePayableToConsignor: boolean,
  soldAmt: number,
  invoices: IItem.ItemPurchaseInvoice[],
) => {
  let amountPayableToPickles = 0;
  let amountPayableToConsignor = 0;
  let totalPaidAmount = 0;
  let totalPendingApprovalAmount = 0;

  // Determine payment status
  const nonAutoSettleInvoices = invoices.filter((invoice) => !invoice.invoiceAutoSettle);

  nonAutoSettleInvoices.forEach((invoice) => {
    amountPayableToPickles += invoice.invoiceAmount;
    totalPaidAmount += invoice.invoicePaidAmount;
    totalPendingApprovalAmount += invoice.invoicePendingApprovalAmount;
  });

  let paymentStatus = IItem.PaymentStatus.pendingPayment;
  const isSold = itemStatus === IItem.STATUS.SOLD;
  const hasSubmission = totalPaidAmount > 0 || totalPendingApprovalAmount > 0;
  const isFullySubmitted = totalPaidAmount + totalPendingApprovalAmount >= amountPayableToPickles;
  const isPartialSubmitted =
    hasSubmission && totalPaidAmount + totalPendingApprovalAmount < amountPayableToPickles;

  if (isSold) {
    paymentStatus = IItem.PaymentStatus.paid;
  } else if (isFullySubmitted) {
    paymentStatus = IItem.PaymentStatus.pendingApproval;
  } else if (isPartialSubmitted) {
    paymentStatus = IItem.PaymentStatus.partialSubmitted;
  }

  // Determine amount payable to consignor
  if (isItemBalancePayableToConsignor) {
    const itemDepositInvoice = invoices.find(
      (invoice) => invoice.invoiceType === IGeneral.InvoiceType.DEPOSITS,
    );
    amountPayableToConsignor = soldAmt - (itemDepositInvoice?.invoiceAmount || 0);
  }

  // Determine non fully paid invoices that still require payment submission by buyer
  const nonFullyPaidInvoices = nonAutoSettleInvoices
    .filter(
      (invoice) =>
        invoice.invoicePaidAmount + invoice.invoicePendingApprovalAmount < invoice.invoiceAmount,
    )
    .sort((invoiceA, invoiceB) => {
      // Sorting the invoice so that buyer premium invoice is always on top as payment will applied to buyer premium invoice first
      if (
        invoiceA.invoiceType === IGeneral.InvoiceType.BUYER &&
        invoiceB.invoiceType === IGeneral.InvoiceType.BALANCE
      ) {
        return -1;
      }
      if (
        invoiceA.invoiceType === IGeneral.InvoiceType.BALANCE &&
        invoiceB.invoiceType === IGeneral.InvoiceType.BUYER
      ) {
        return 1;
      }
      return 0;
    });

  return {
    paymentStatus,
    amountPayableToPickles,
    amountPayableToConsignor,
    totalPaidAmount,
    totalPendingApprovalAmount,
    invoices: nonFullyPaidInvoices,
  };
};
